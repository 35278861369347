export const environment = {
  production: true,
};

const prodEnvsApisMap = new Map([
  ['https://admin.elevatix.io', 'https://api.elevatix.io'],
  ['https://app.elevatix.io', 'https://api.elevatix.io'],
  ['https://dev.admin.elevatix.io', 'https://dev.api.elevatix.io'],
  ['https://prod.admin.elevatix.io', 'https://prod.api.elevatix.io'],
  ['https://dev-new.admin.elevatix.io', 'https://dev-new.api.elevatix.io'],
]);
const baseDomain = prodEnvsApisMap.get(window.location.origin) ?? `${window.location.origin}/api`;

export const endpoints = {
  auth: {
    signIn: `${baseDomain}/back-office/auth/login`,
    refresh: `${baseDomain}/back-office/auth/refresh`,
    signOut: `${baseDomain}/back-office/auth/logout`,
    signUp: `${baseDomain}/back-office/open/public/application/registration`
  },
  user: {
    userInfo: `${baseDomain}/elevatix-backend/public/user`,
  },
  customer: {
    getCustomerByEmail: `${baseDomain}/back-office/public/customer`,
    getCustomersList: `${baseDomain}/back-office/public/customer/list`,
    passwordChange: `${baseDomain}/back-office/public/password/change`,
    invite: `${baseDomain}/back-office/public/registration`,
    confirmInvite: `${baseDomain}/back-office/public/confirm`
  },
  onboarding: {
    getUserInfo: `${baseDomain}/elevatix-backend/public/onboarding`,
    getPlayersList: `${baseDomain}/elevatix-backend/public/onboarding/user/search`,
  },
  applications: {
    getApplications: `${baseDomain}/back-office/public/application`,
    stores: `${baseDomain}/inapp-configuration-service/admin/application/stores`,
    statistics: {
      revenue: {
        total: `${baseDomain}/statistic-service/public/revenue`,
        shop: `${baseDomain}/statistic-service/public/revenue/shop`,
        offers: `${baseDomain}/statistic-service/public/revenue/offers`,
      },
      // Average LTV
      LTV: {
        daily: `${baseDomain}/statistic-service/public/gross/daily`,
      },
      OffersLTV: {
        daily: `${baseDomain}/statistic-service/public/offers/daily`,
      },
      // Paying share
      payingShare: {
        daily: `${baseDomain}/statistic-service/public/payingUsers/daily`,
      },
      // New users
      newUsers: {
        daily: `${baseDomain}/statistic-service/public/userRegistration/daily`,
      },
      // ??
      averageBill: {
        daily: `${baseDomain}/statistic-service/public/averageBill/daily`,
      },
      paymentsQuantity: {
        daily: `${baseDomain}/statistic-service/public/payment/quantity/daily`,
      },
      arppu: {
        daily: `${baseDomain}/statistic-service/public/arppu/daily`,
      },
      arpdau: {
        daily: `${baseDomain}/statistic-service/public/arpdau/daily`,
      },
      dau: {
        daily: `${baseDomain}/statistic-service/public/dau/daily`,
      },
      campaigns: `${baseDomain}/statistic-service/public/campaigns`,
      metrics: `${baseDomain}/statistic-service/public/header`,
      paymentFunnel: `${baseDomain}/statistic-service/public/payment/funnel`,
      firstPayment: `${baseDomain}/statistic-service/public/payment/first`,
      repeatedPayments: {
        daily: `${baseDomain}/statistic-service/public/payment/repeated/daily`,
      },
    },
    products: {
      product: `${baseDomain}/inapp-configuration-service/admin/products`,
      productUsages: `${baseDomain}/inapp-configuration-service/admin/products/usage`,
      getProducts: `${baseDomain}/inapp-configuration-service/admin/products/list`,
    },
    costPackages: {
      costPackage: `${baseDomain}/inapp-configuration-service/admin/cost-package`,
      costPackageUsages: `${baseDomain}/inapp-configuration-service/admin/cost-package/usage`,
      costPackages: `${baseDomain}/inapp-configuration-service/admin/cost-package/list`,
    },
    mobileProducts: {
      mobileProduct: `${baseDomain}/inapp-configuration-service/admin/mobile-products`,
      mobileProducts: `${baseDomain}/inapp-configuration-service/admin/mobile-products/list`,
    },
    packs: {
      packs: `${baseDomain}/inapp-configuration-service/admin/packs/list`,
      specific: `${baseDomain}/inapp-configuration-service/admin/packs/specific`,
      pack: `${baseDomain}/inapp-configuration-service/admin/packs`,
    },
    segmentation: {
      list: `${baseDomain}/campaign-service/admin/segments`,
      state: `${baseDomain}/user-segmentation/public/segment/state`,
      transitions: `${baseDomain}/user-segmentation/public/segment/transition`,
      history: {
        daily: `${baseDomain}/user-segmentation/public/segment/daily/history`,
        weekly: `${baseDomain}/user-segmentation/public/segment/weekly/history`,
        monthly: `${baseDomain}/user-segmentation/public/segment/monthly/history`,
      },
    },
    profiles: {
      list: `${baseDomain}/campaign-service/admin/profiles`,
    },
    campaign: {
      single: `${baseDomain}/campaign-service/admin/campaigns`,
      list: `${baseDomain}/campaign-service/admin/campaigns/list`,
      autoGenerated: `${baseDomain}/campaign-service/admin/campaigns/auto`,
      templates: `${baseDomain}/campaign-service/admin/campaigns/template`,
      statusUpdate: `${baseDomain}/campaign-service/admin/campaigns/status`,
      bulk: `${baseDomain}/campaign-service/admin/campaigns/custom/packs`,
      trigger: `${baseDomain}/campaign-service/internal/trigger`,
      userCampaigns: `${baseDomain}/campaign-service/internal/recommendations`,
    },
    strategy: {
      list: `${baseDomain}/client-facade/admin/strategy`,
    },
    fakeCampaign: {
      save: `${baseDomain}/client-facade/internal/fake/recommendation`,
      availability: `${baseDomain}/client-facade/admin/fake/availability`,
    },
    triggers: {
      list: `${baseDomain}/campaign-service/admin/custom/triggers`,
      single: `${baseDomain}/campaign-service/admin/custom/triggers/single`,
    },
  },
  stores: `${baseDomain}/inapp-configuration-service/admin/store`,
  staticStorage: {
    preSignedUrls: `${baseDomain}/back-office/public/generate/url`,
  },
};

